const colorPalette = {
  primary: "#5F2DED",
  secondary: "#6E0A0F",
  purple: "#5F2DED",
  textPrimary: "#101212",
  textSecondary: "#4F4444",
  textDisabled: "#ADADAD",
  successPrimary: "#2DA061",
  successSecondary: "#E6F7EE",
  errorPrimary: "#E3554D",
  errorSecondary: "#FFEEED",
  warningPrimary: "#D58035",
  warningSecondary: "#FFFEEF",
  white: "#FFFFFF",
  cavernPink: "#DEB7BA",
  codGray: "#121212",
  lightGray: "#C8C8C8",
  dawnPink: "#F3E4E5",
  gray: "#656565",
  aquaHaze: "#f3f2f2",
  offWhite: "#F8F8F8",
  mutedGray: "#DBDBDB",
  mistGray: "#F3F2F2",
  frost: "#FCFCFC",
  charcoalGray: "#6A6464",
  slateBlueGray: "#667085",
  hayleyDarkBrown: "#d48035",
  black: "#000000",
  flashWhite: "#F2F4F7",
  cyanBlue: "#3575D4",
  solitudeGray: "#F2F4F7",
  cloudyGray: "#6a6464",
  hanBlue: "#3575D4",
  duskPurple: "#F2F5FF",
  lightGrayFooter: "rgba(191, 191, 191, 0.25)",
  yellow: "#fdc313",
  fulvous: "#EA8A00",
};

export default colorPalette;
